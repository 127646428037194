import BaseTopNav from '@/components/base/baseTopNav';
import PageEnum from '@/enums/pageEnum';
import ThemeEnum from '@/enums/themeEnum';
import CustomRadio from '@/pages/mine/theme/components/CustomRadio';
import { RadioGroup } from '@nextui-org/react';
import { useTheme } from 'next-themes';
import { useTranslation } from 'react-i18next';

export default function Mine() {
  const { t } = useTranslation();

  const { theme, setTheme } = useTheme();

  const cardArr4: any = [
    {
      key: 'light',
      label: t('白天'),
      url: PageEnum.USER_LANGUAGE,
      img: require('@/assets/img/light.png'),
      onclick: () => {
        setTheme(ThemeEnum.light);
        window.FlutterTheme?.postMessage(ThemeEnum.light);
      },
    },
    {
      key: 'dark',
      label: t('黑夜'),
      img: require('@/assets/img/dark.png'),
      onclick: () => {
        setTheme(ThemeEnum.dark);
        window.FlutterTheme?.postMessage(ThemeEnum.dark);
      },
    },
  ]?.filter((i) => !!i);

  return (
    <div>
      <BaseTopNav />
      <div className="px-4 mt-4">
        <div className="text-[18px] leading-[26px] text-titleColor font-[600]">
          {t('主题切换')}
        </div>
        <div className="mt-[4px]">
          <div className="text-[14px] leading-[22px] text-auxiliaryTextColor sm:mt-3">
            {t('开启后，主题跟随系统设定为白天模式或黑夜模式')}
          </div>
        </div>
      </div>
      <div className="px-4 pt-6">
        <RadioGroup
          orientation="horizontal"
          classNames={{
            wrapper: 'w-full justify-around',
          }}
          value={theme}
        >
          {cardArr4?.map((item: any, index: number) => {
            return (
              <CustomRadio
                className="w-full"
                key={item.key}
                value={item.key}
                description={
                  <>
                    <img
                      src={item?.img}
                      alt=""
                      className={`border-solid border-[2px] rounded-[12px] ${
                        theme === item.key ? 'border-primary' : 'border-borderColor'
                      }`}
                    />
                  </>
                }
                onClick={item.onclick}
              >
                <span className="text-[14px] leading-[22px]">{item.label}</span>
              </CustomRadio>
            );
          })}
        </RadioGroup>
        {/* {cardArr4?.map((item: any, index: number) => {
          return (
            <div
              className="flex py-3 items-center justify-between cursor-pointer"
              key={item.key}
              onClick={() => {
                setTheme(item.key);
                window.FlutterTheme?.postMessage(item.key);
              }}
            >
              <div className="">{item.label}</div>
              {theme === item.key && (
                <IconParkSolidCorrect className="text-primary" />
              )}
            </div>
          );
        })} */}
      </div>
    </div>
  );
}
