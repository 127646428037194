import { VisuallyHidden, cn, useRadio } from '@nextui-org/react';

export default (props: any) => {
  const {
    Component,
    children,
    description,
    getBaseProps,
    getWrapperProps,
    getInputProps,
    getLabelProps,
    getLabelWrapperProps,
    getControlProps,
  } = useRadio(props);

  return (
    <Component
      {...getBaseProps()}
      className={cn(
        'w-[122px] group inline-flex flex-wrap items-center hover:opacity-100 active:opacity-100 justify-between flex-row-reverse tap-highlight-transparent',
        'max-w-[300px] cursor-pointer  rounded-lg gap-4 ',
        // 'data-[selected=true]:border-primary',
      )}
    >
      <VisuallyHidden>
        <input {...getInputProps()} />
      </VisuallyHidden>
      <div className="w-full flex flex-wrap justify-around">
        <div {...getLabelWrapperProps()} className="!w-full mb-4">
          {description && (
            <span className="text-small text-foreground opacity-100">
              {description}
            </span>
          )}
        </div>
        <div className="w-full flex justify-center">
          <span className="" {...getWrapperProps()}>
            <span {...getControlProps()} />
          </span>
          {children && (
            <span {...getLabelProps()} className="!w-fit ml-2">
              {children}
            </span>
          )}
        </div>
      </div>
    </Component>
  );
};
